import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import styled, { css } from 'styled-components'

import { Layout, Block } from '../components'

import { container, padding, button, pageTitle } from '../styles/global';
import { slugify } from '../utils';
import { media } from '../styles/utils';
import { gold } from '../styles/colors';


const VenueAbout = ({pageContext, ...props}) => {
	const data = JSON.parse(pageContext.acf_json)

	const renderSlider = (images) => {
		return (
			<Block
				layout={'slider'}
				images={images}
				hoverArrows
			/>
		)
	}	

	const renderSections = () => {
		if (!data.sections || !Array.isArray(data.sections)) return;

		return data.sections.map((item, i) => {
			return (
				<Section 
					key={i}
					id={slugify(item.title)}
				>
					{renderSlider(item.image_slider)}
					
					<Heading>{item.title}</Heading>
					<Description
						dangerouslySetInnerHTML={{__html: item.description}}
					/>

					{item.use_enquire_button && (
						<Button
							href="https://www.pepesitalian.com.au/pepes/functions#enquire"
							target={'_blank'}
						>
							{item.enquire_text}
						</Button>
					)}
				</Section>
			)
		})
	}	

	return (
		<Layout
			meta={data && data.seo}
			menu={pageContext.menu}
		>

			<Container
				parent={pageContext.menu.parent}
			>
				<Heading>
					{pageContext.sectionTitle}
				</Heading>
				<Menu>
			{data.sections && Array.isArray(data.sections) ? data.sections.map(section => (
				<MenuItem>
				<Link to={`#${slugify(section.title)}`}>
				<Text>{section.title}</Text>
				</Link>
				</MenuItem>
			)) : null}
			</Menu>
				{renderSections()}
			</Container>
		</Layout>	
	)
}

const Menu = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	padding: 0;
	margin: 0;
	z-index: 1;
	margin-top: 10px;

	${media.phone`
	flex-wrap: wrap;
	bottom: 10%;
	`}
`


const MenuItem = styled.div`
	padding: 10px 15px;
	background-color: #ffffff87;
	margin-right: 5px;
	margin-bottom: 10px;
	border-radius: 5px;
`

const Text = styled.div`
    font-size: 1.5em;
    line-height: 26px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
`

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Button = styled.a`
	${button}
`

// Layout

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;

	> ${Heading} {
		${pageTitle}
		${'' /* position: fixed; */}
		top: 0;
		left: 0;
		right: 0;
		z-index: 0;
		padding-bottom: 0px;
		
		text-align: center;
		background: white;
		background-color: #F1BFB1;

		${props => {
			if (props.parent && props.parent.slug == 'trunk-diner') return css`
				/* background: ${gold}; */
			`
		}}

		${media.tablet`
			padding: 32px 0 26px;
			top: 45px;
		`}
	} 
`

// Sections

const Section = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 50px;

	${Heading} {
		font-family: 'learning-curve';
		font-size: 40px;
		margin: 48px 0 40px;
		align-self: center;
		font-weight: bold;

		${media.phone`
			font-size: 24px;
			margin: 20px 0 20px;
		`}
	}

	${Description} {
		max-width: 620px;
		font-weight: bold;
		font-size: 22px;
		text-align: center;
		line-height: 24px;
		text-transform: uppercase;
	}

	${Button} {
		margin-top: 100px;
	}

	&:last-child {
		margin-bottom: 178px;
	}

	${media.tablet`
		&:nth-child(2) {
			padding-top: 50px;
		}
	`}

	${media.phone`
		padding-top: 48px;

		&:last-child {
			margin-bottom: 96px;
		}

		&:nth-child(2) {
			padding-top: 73px;
		}

		${Button} {
			margin-top: 48px;
		}
	`}
`

export default VenueAbout

